.passwordStrengthChecker___34ME7 {
  position: relative;
  width: 100%;
}
.passwordStrengthChecker___34ME7 .checker {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  right: 0;
  padding: 12px 20px;
  background: #ffffff;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
}
.passwordStrengthChecker___34ME7 .checker:before {
  content: '';
  position: absolute;
  top: -10px;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: #ffffff transparent;
}
.passwordStrengthChecker___34ME7 .checker .icon-wrapper {
  display: inline-block;
  width: 20px;
  height: 14px;
  text-align: center;
}
.passwordStrengthChecker___34ME7 .checker .icon-wrapper .dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #636371;
  vertical-align: 3px;
}
.passwordStrengthChecker___34ME7 .checker .rules-title,
.passwordStrengthChecker___34ME7 .checker .strength-title {
  font-size: 18px;
  color: #636371;
  font-weight: 600;
}
.passwordStrengthChecker___34ME7 .checker .password-strength {
  display: flex;
  margin-top: 20px;
}
.passwordStrengthChecker___34ME7 .checker .password-strength .strength-degree {
  position: relative;
  height: 4px;
  margin-left: 8px;
}
.passwordStrengthChecker___34ME7 .checker .password-strength .strength-degree .degree-desc {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: -8px;
}
