/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.header___1Z92M {
  display: flex;
  height: 64px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: fixed;
  top: 0;
  z-index: 999;
  right: 0;
  transition: left 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  padding: 0 15px;
}
@media (max-width: 575px) {
  .header___1Z92M {
    padding: 0;
  }
}
.left___LCbck {
  display: inline-flex;
  flex-wrap: nowrap;
  overflow: hidden;
  flex: 1;
  min-width: 0;
}
.logo___kH473 {
  display: inline-block;
  height: 64px;
  padding: 0 10px;
  font-size: 20px;
  line-height: 64px;
  vertical-align: top;
  border-radius: 50%;
  cursor: pointer;
}
.logo___kH473 img {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}
.menu___3fMWW .anticon {
  margin-right: 8px;
}
.menu___3fMWW .ant-dropdown-menu-item {
  min-width: 160px;
}
.trigger___BOOyT {
  height: 64px;
  padding: 0 8px;
  font-size: 24px;
  line-height: 64px;
  cursor: pointer;
  transition: all 0.2s, padding 0s;
}
.trigger___BOOyT:hover {
  background: rgba(0, 0, 0, 0.025);
}
.restaurantLogo___HGYaE {
  width: 256px;
  text-align: center;
  line-height: 64px;
  margin-left: -15px;
}
.restaurantSwitcher___3HMRe {
  height: 64px;
  line-height: 64px;
}
.restaurantSwitcher___3HMRe .restaurant-panel {
  position: absolute;
  top: 64px;
  z-index: 2;
  padding: 15px;
  display: none;
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  width: 586px;
  line-height: 1;
}
.restaurantSwitcher___3HMRe .restaurant-panel.visible {
  display: block;
}
.restaurantSwitcher___3HMRe .restaurant-panel .ant-tree-node-content-wrapper {
  padding: 6px;
}
.restaurantSwitcher___3HMRe .restaurant-panel .ant-tree-switcher {
  width: 0;
  display: none;
}
.restaurantSwitcher___3HMRe .restaurant-panel .tree-container {
  max-height: calc(100vh - 64px - 65px);
  overflow-y: auto;
}
.right___2CMz5 {
  height: 100%;
}
@media (min-width: 992px) {
  .right___2CMz5 {
    min-width: 600px;
  }
  .right___2CMz5 .ant-menu {
    justify-content: end;
  }
}
.right___2CMz5 .action___3ut1O {
  display: inline-block;
  height: 100%;
  padding: 0 12px;
  line-height: 64px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.right___2CMz5 .action___3ut1O:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.right___2CMz5 .icon_reset_password___2Z1TI {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background-image: url('https://pos-static.chowbus.com/assets/icon_reset_password.png');
  background-size: 100%;
}
.right___2CMz5 .ant-popover-inner-content {
  padding: 0;
}
.right___2CMz5 .ant-menu-horizontal {
  line-height: 64px;
  border-bottom: none;
}
.right___2CMz5 .ant-menu-horizontal .ant-menu-submenu {
  padding: 0 10px;
}
.right___2CMz5 .ant-menu-horizontal .ant-menu-submenu-popup {
  z-index: 99999;
}
.right___2CMz5 .ant-menu-horizontal > .ant-menu-submenu-selected::after {
  border: none;
}
.right___2CMz5 .ant-menu > .ant-menu-submenu-selected {
  color: rgba(0, 0, 0, 0.85);
}
