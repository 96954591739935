/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ResetPasswordByEmail___ZoXct {
  font-size: 16px;
}
.ResetPasswordByEmail___ZoXct .ant-form-item-label {
  line-height: 40px;
}
.section-email___1mSRx {
  margin-bottom: 20px;
}
