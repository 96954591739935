.ReactCodeInput___2GuTz input {
  text-align: center;
  background-color: #fff;
  background-image: none;
  padding: 0;
  margin: 0 4px;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  outline: none;
}
.ReactCodeInput___2GuTz input.large {
  width: 40px;
  height: 40px;
  font-size: 32px;
  line-height: 40px;
}
.ReactCodeInput___2GuTz input.middle {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 22px;
}
.ReactCodeInput___2GuTz input.disabled {
  cursor: not-allowed;
  color: #d3d3d3;
  border-color: #d3d3d3;
  background-color: #efeff1;
}
