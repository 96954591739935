/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.logo___11PiT {
  position: relative;
  height: 64px;
  padding-left: 24px;
  overflow: hidden;
  line-height: 64px;
  background-color: #ffffff;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}
.logo___11PiT img {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}
.logo___11PiT h1 {
  display: inline-block;
  margin: 0 0 0 12px;
  color: white;
  font-weight: 600;
  font-size: 20px;
  vertical-align: middle;
}
.menuWrapper___1VMWR {
  position: relative;
  height: calc(100vh - 64px);
  padding-top: 2px;
  overflow: auto;
}
.sider___3DNJN {
  position: relative;
  z-index: 10;
  min-height: 100vh;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.sider___3DNJN.fixSiderBar___2avJv {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}
.sider___3DNJN.fixSiderBar___2avJv .ant-menu-inline {
  border-right: 0;
}
.sider___3DNJN.fixSiderBar___2avJv .ant-menu-inline .ant-menu-item,
.sider___3DNJN.fixSiderBar___2avJv .ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.sider___3DNJN .ant-menu-light {
  border-right-color: transparent;
}
.icon___bWdA8 {
  width: 14px;
  vertical-align: baseline;
}
